nav{
    position: absolute;
    margin-left: clamp(0px, 0px + 5vw,  40px);
    width: clamp(180px, 10px + 17vw,  233px);
    /* gradient/light */
    background: linear-gradient(144deg, #DED6EE 13.51%, #A0D4F1 105.7%);
    /* main shadow */
    box-shadow: 0px 0px 30px rgba(16, 16, 16, 0.12);
    
    border-radius: 0px 0px 50px 0px;
    z-index: 2;
}
ul{
    all:unset;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    padding: 100px 30px;
    padding-top: clamp(25px, 10px + 5vw,  100px);
    padding-bottom: clamp(25px, 10px + 5vw,  100px);
    padding-left: clamp(5px, 5px + 3vw,  30px);
    padding-right: clamp(5px, 5px + 3vw,  30px);
    
}
li{
    all:unset;
    font-style: normal;
    font-weight: 300;
    font-size: clamp(18px, 0px + 2vw,  21px);
    line-height: 25px;
    padding-top: clamp(5px, 5px + 1vw,  20px);
    padding-bottom: clamp(5px, 5px + 1vw,  20px);
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 2;
    width: 100%;
}
li:hover {
    font-weight: 400;
}

.nav-icons{
    font-size:  clamp(25px, 0px + 2.5vw,  32px);
    padding-right: clamp(5px, 2px + 1vw,  20px);;
}
.centerLink{
    display: flex;
    align-items: center;
}
.menu{
    display: block;
}
.mobile{
    display: none;
}
.linkheader{
    display: flex !important;
    align-items: center !important;
}
@media screen and (max-width: 499px) {
nav{
    margin-left: 0;
    width: auto;
}
ul{
    padding: 20px;
}
.menu{
    padding-top: 0;
}
.mobile{
    display: block;
    padding: 15px;
    padding-bottom: 0;
    text-align: left;
}
}