
.App {
    text-align: center;
    /* background: linear-gradient(144deg, #ded6ee30 13.51%, #A0D4F130 105.7%); */
    max-width: 2000px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
a{
    all: unset;
}
p {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 25px;

/* Main dark */

color: #3A2C58;

}
#root{
    min-height: 100vh;
}
section{
    width: 100%;
}

.mainDesc{
    padding-top: clamp(25px, 25px + 5vw,  100px);
    padding-bottom: 50px;
    padding-right: 40px;
    max-width: 850px;
    margin-left: clamp(200px, 150px + 13vw,  350px);
    margin-right: auto;
    text-align: left;
    min-height: 100%;
}
.Content{
    padding-top: clamp(25px, 25px + 5vw,  100px);
    padding-bottom: 50px;
    padding-right: 0px;
    max-width: 1000px;
    margin-left: clamp(200px, 150px + 13vw,  350px);
    margin-right: auto;
    text-align: left;
    min-height: 100%;

}
footer{
    margin-top: clamp(30px, 0px + 15vw, 200px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
}
textarea{
    position: relative;
    all: unset;
    width: 100%;
    height: 60px;
    left: 0px;
    top: 0px;

    background: #FFFFFF;
    border: 1px solid  #D8D6EF;;
    border-radius: 16px;
   
    text-indent: 30px;
    box-sizing: border-box;
    padding: 20px;
    z-index: 2;
}

input{
    width: 100%;
    
    all: unset;
    width: 100%;
    height: 60px;
    left: 0px;
    top: 0px;

    background: #FFFFFF;
    border: 1px solid  #D8D6EF;;
    border-radius: 16px;
    text-indent: 30px;
    box-sizing: border-box;
    padding-right: 20px;
    padding-left: 20px;
}
h3{
    font-family: 'Oswald', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    text-transform: uppercase;

    /* Light/secondary */

    color: #FEC5C5;
    margin-bottom: 0;}

h4{
    margin-top: 0;
    margin-bottom: 0;
    font-family: 'Oswald', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 53px;

/* Main dark */

color: #3A2C58;
}
h6{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #3A2C58;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
}
hr{
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #DED6EE;
    margin: 17px 20px 10px 20px;
    padding: 0;
}
/* Background */
.halfcircle{
    position: absolute;
    pointer-events: none;
    mix-blend-mode: inherit;
    left: 0;
    z-index: 4;
    margin-top: clamp(125px, 85px + 16vw,  350px);
    margin-left: clamp(-75px, -125px - 15vw,  -100px);
    width: clamp(50px, 50px + 18vw, 300px);
}
.mainDesc {
    display: flex;
}
.mainDesc .container{
    width: auto;
}
.divider{
    position: absolute;
    left: 0px;
    margin-top: -600px;
    z-index: -2;
}
.divider1{
    position: absolute;
    pointer-events: none;
    left: 0;
    margin-top: -500px;
    z-index: -1;
    transform: scaleX(-1);
}
/* Footer */
.divider2{
    position: absolute;
    pointer-events: none;
    bottom: 0;
    right: 0;
    margin-right: -60px;
    width: 100%;
    margin-bottom: 150px;
    transform: scaleX(-1);
    z-index: -1;
}
.divider3{
    position: absolute;
    pointer-events: none;
    z-index: -1;
    margin-top: -500px;
    margin-right: -75px;
    margin-left: 0px;
    right: 0;
}
.divider4{
    pointer-events: none;
    margin-top: -400px;
    margin-left: -350px;
    transform: scaleX(-1);
    left: 0;
}
.singleitemDescription{
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #3A2C58;

    margin: 0px 20px;
}
.top{
    width: 300px;
   height:  150px;
}
.collection{
    display: flex;
    margin-top: 40px;
    flex-wrap: wrap;
    justify-content: space-around;
}


.arrow{
    width: 94px;

}
.nextPage{
    all:unset;
    font-family: 'Oswald', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    text-align: right;
    /* Grey */
    color: #746F7E;
    cursor: pointer;
}
.loadingScreen{
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.linkd{
    cursor: pointer;
}
@media screen and (max-width: 1499px) {
  .Content{
    padding-left: 25px;
    padding-right: 25px;

    padding-bottom: 50px;

    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    min-height: 100%;
    overflow-x: hidden;
    }
    .collection{
        justify-content: space-evenly;;
    }
}
@media screen and (max-width: 1079px) {
  .divider{
    display: none;
  }
}
@media screen and (max-width: 499px) {
    .mainDesc{
        margin-left: 0;
        padding-left: 40px;
    }
    .halfcircle{
        display: none;
    }
    
}
.linkd{
    position: absolute;
    right: 75px;
    
}
.signature{
    height: 75px;
}
@media screen and (max-width: 750px) {
.linkd{
    all:unset;
    position: absolute;
    left: 25px;
}
.signature{
    height: 55px;
}
}
@media screen and (max-width: 364px) {
.linkd{
    all:unset;
    position: absolute;
    left: 25px;
}
.signature{
    height: 30px;
}
}