
#root{
    background: linear-gradient(144deg, #ded6ee30 13.51%, #A0D4F130 105.7%);
}

.maincontainer{
    background: linear-gradient(0deg, #402C58 -2.23%, #442E5E -2.22%, #ACE5FE 119.43%);
    margin-left: -300%;
    margin-right: -300%;
}
.main{
    margin-left: auto;
    margin-right: auto;
    height: 100vh;
    max-height: 980px;
    z-index: -1;
    max-width: 2000px;
    background: linear-gradient(0deg, #402C58 -2.23%, #442E5E -2.22%, #ACE5FE 119.43%);
    overflow-x: hidden;
}
.details{
    padding: clamp(10px, 10px + 5vw,  80px);
    max-width: 1000px;
    margin-left: clamp(180px, 100px + 13vw,  350px);
    
    text-align: right;
    z-index: 1;
    position: relative;
}
.frame1{
    width: clamp(150px, 100px + 12vw,  310px);
    height: clamp(150px, 100px + 12vw,  310px);
    background: linear-gradient(176.46deg, #402C58 -2.23%, #442E5E -2.22%, #ACE5FE 119.43%);
    /* main shadow */
    box-shadow: 0px 0px 30px 3px rgba(16, 16, 16, 0.12);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.portrait{
    height: 95%;
    border-radius: 100%;
}
h2{
    font-family: 'Oswald', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
    margin-top: clamp(0px, 0px + 1vw,  25px);
    font-style: normal;
    font-weight: 700;
    font-size: clamp(30px, 30px + 2vw,  64px);
    line-height: clamp(40px, 30px + 3vw,  85px);
    color: #FFFFFF;
    text-align: left;
    margin-bottom: -45px;
}
h1{
    font-style: normal;
    font-weight: 300;
    font-size: clamp(20px, 20px + 2vw,  48px);
    line-height: clamp(30px, 20px + 3vw,  60px);
    color: #FFFFFF;
    opacity: 0.8;
    text-align: left;
    margin-bottom: 0px;
}
.btn_mainmore{
    border: 1px solid #FEC5C5;
    /* light */
    box-shadow: 0px 4px 20px -5px rgba(16, 16, 16, 0.08);
    border-radius: 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    /* Light/secondary */
    color: #FEC5C5;
    padding: 20px 40px;
    margin-top: -45px;
    margin-bottom: auto;
    scroll-behavior: smooth;
    cursor: pointer;
}
.btn_mainmore:hover{
    border: 1px solid #FEC5C5;
    color: #3A2C58;
    background: #FEC5C5;
}

/* Background */

.geometric1{
    position: absolute;
    pointer-events: none;
    z-index: 1;
    top: 400px;
    left: -200px;
}
.geometric2{
    position: absolute;
    pointer-events: none;
    z-index: 0;
    opacity: 0.1;
    left: 150px;
    mix-blend-mode: soft-light;

}

/* Section 2 */
.information{
    margin-left: auto;
    margin-right: auto;
    padding-top: clamp(40px, 0px + 5vw, 100px);
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding-left: 40px;
    padding-right: 40px;
}
.welcome {
    text-align: left;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    display: flex;

}
.welcome .container{
    width: 90%;

}


.btn_welcome{
    all:unset;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    /* Main dark */

    color: #3A2C58;
    background: #FEC5C5;
    border: 1px solid #FFFFFF;
    /* light */

    box-shadow: 0px 4px 20px -5px rgba(16, 16, 16, 0.08);
    border-radius: 16px;
    height: 60px;
    width: 200px;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}
.btn_welcome:hover{
    border: 1px solid #3A2C58;
    color: #FEC5C5;
    background: #413162;
}

.container2{
    margin-top: auto;
}
.geometric1w {
    position: absolute;
    mix-blend-mode: screen;

    opacity: 0.5;
    width: 230px;
    z-index: -1;
    margin-left: -215px;
    margin-top: 15px;
}


/* Section 3 */
.contact{
    display: flex;
    width: 100%;
    margin-top: clamp(40px, -75px + 25vw, 240px);
    text-align: left;
    
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: flex-end;

}
.inputcontainer{
    display: flex;
    flex-direction: column;
}


.i_email{
    margin-top: 40px;
}
.i_text{
    margin-top: 20px;
    height: 225px;
    vertical-align: top;
    line-height: normal;
}
.containercontact{
    width: 100%;
}
.contfind{
    width: 35%;
    display: flex;
    justify-content: center;
    margin-right: 5%;
 flex-direction: column-reverse;
}
.divider2{
    position: absolute;
    right: 0;
    margin-top: -280px;
    transform: scaleX(-1);
    z-index: -1;
}

.arrow{
    width: 94px;

}
/* Footer */

.mainlink{
    text-transform: uppercase;
    margin-left: 2px;
}
.geometric3{
    position: absolute;
    z-index: -1;
    opacity: 0.1;
    left: 150px;
    margin-top: 250px;
    mix-blend-mode: soft-light;
    height: 400px;
    transform: rotate(120deg);
}
.input-icons{
    color: #746F7E;
}

.iconmessage{
    position: absolute;
    margin-top: 45px;
    margin-left: 20px;
    z-index: 3;
}
.iconemail{
    position: absolute;
    margin-top: 60px;
    margin-left: 20px;
    z-index: 3;
}
.emailcontainer{
    position: relative;
}

textcontainer{
    position: relative;
}
.iconmessage{
    position: absolute;

}
.contactform-icons{
    font-size: 35px;
}

@media screen and (max-width: 2000px) {
  .maincontainer {
    margin: 0;
  }
}
@media screen and (max-width: 1379px) {
  .main{
    height: auto;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 1079px) {
   .btn_mainmore{
    display: none;
   }
  .welcome .container2{
    display: none;
  }
  .welcome .container{
    width: 100%;
    max-width: 700px;
  }
  .geometric1{
    top: 300px;
    left: -100px;
    height: 270px;
  }
  .contact{
    display: flex;
    flex-direction: column-reverse;
    max-width: 700px;
  }
  .btn_welcome{
    margin: 0;
  }
  .contfind{
    width: auto;
    margin: 0;
    justify-content: flex-start;
    margin-top: 10px;
  }
}
@media screen and (max-width: 499px) {
    .details{
        margin-left: 0;
     
    }
    .geometric1{
        top: 425px;
        right: 0;
        left: auto;
        height: 125px;

    }
    
}